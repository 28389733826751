 /* Media Query for Mobile Devices */
 /* @media (max-width: 480px) {
.disp-none{
    display: none;
}
} */

  /* Media Query for low resolution  Tablets, Ipads */
  /* @media (min-width: 481px) and (max-width: 767px) {
    body {
        background-color: yellow;
    }
} */
  
/* Media Query for Tablets Ipads portrait mode */
/* @media (min-width: 768px){
    body {
        background-color: blue;
    }
} */
.MuiToolbar-root{
    min-height: 50px;
}
.MuiAppBar-root{
    box-shadow: none;
}
.selectedColor{
    background-color: #74ebd56e;
}